import { envDef, firebaseConfig } from 'src/environments/env.default';

export const environment = {
    ...envDef,
    prefix: 'gerencie',
    version: '14.0.3',
    urlAPI: 'https://gerencie.prod.api.gestor.ladder.dev.br',
    urlBase: 'gerencie.app',
    CrossDomainStorageOrigin: 'https://gerencie.prod.api.gestor.ladder.dev.br',
    firebase: firebaseConfig
};
